import { defineStore } from 'pinia'
import { cast } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
import { VERSION as ACQUISIT_UI_VERSION } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'

export enum Origin {
	CLASSIC = 'classic',
	UI_BUILDER = 'uib'
}

export interface GenericStoreState {
	backup_enabled: boolean
	build: string
	ui: string
	origin: Origin
	
	dev: {
		submission_payload_validated_only: boolean
		menu_open: boolean
	}
}

export const useGenericStore = defineStore({
	id: 'generic',
	
	state: () => cast<GenericStoreState>({
		backup_enabled: !import.meta.env.DEV,
		build: import.meta.env.BUILD,
		ui: ACQUISIT_UI_VERSION,
		origin: Origin.CLASSIC,
		
		dev: {
			submission_payload_validated_only: true,
			menu_open: false
		},
	}),
	
	actions: {
		setDevSubmissionPayloadValidatedOnly(state: boolean) {
			this.dev.submission_payload_validated_only = state
		},
		
		setDevMenuOpen(state: boolean) {
			this.dev.menu_open = state
		},
		
		setBackupEnabled(state: boolean) {
			this.backup_enabled = state
		},
		
		setOrigin(origin: Origin) {
			this.origin = origin
		},
		
		async checkForUpdates() {
			try {
				const response = await fetch('/latestVersion.json')
				
				if (response.status >= 400) {
					console.error('Error checking for updates', response)
					return false
				}
				
				const data = await response.json()
				
				if (data.build && data.build > import.meta.env.BUILD_NUMERIC) {
					console.info('Update', data.version, 'available')
					return true
				}
			} catch (e: any) {
				console.error('Error checking for updates', e)
			}
			
			return false
		}
	}
})