<template>
	<div id="acquisit-signature-pad">
		<div class="canvas-wrapper">
			<div class="canvas-underlay" v-if="!disabled && isOverlayVisible && !savedSignature">
				<acquisit-string class="placeholder" v-if="helpText" :source="helpText" />
				<slot name="placeholder"></slot>
			</div>
			
			<div class="canvas-overlay" v-if="disabled && $slots.disabled">
				<slot name="disabled"></slot>
			</div>
			
			<div class="canvas-overlay" v-else-if="!savedSignature && disabled && disabledLabel">
				<span class="disabled">{{ disabledLabel }}</span>
			</div>
			
			<canvas :class="[{ disabled }]"
					@change="saveCanvas"
			        @touchstart="onTouchStart"
			        @touchmove="onTouchMove"
			        @touchend="onTouchEnd"
			        @mousedown="onMouseDown"
			        @mouseup="onMouseUp"
			        @mousemove="onMouseMove"
			        ref="$canvas"></canvas>
		</div>
	</div>
</template>

<script setup lang="ts">
	import type { PropType } from 'vue'
	import type { UILabelOptional } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
	import { useLogger } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
	import { useEventListener, useColorProps, useColor } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/helpers'
	import { cast } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
	import { onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue'
	
	interface Position {
		x: number,
		y: number
	}
	
	const props = defineProps({
		...useColorProps(),
		
		signature: {
			type: String,
			required: false,
			default: ''
		},
		
		helpText: {
			type: [String, Object] as PropType<UILabelOptional>,
			required: false,
			default: null
		},
		
		modelValue: {
			type: String as PropType<string|null>,
			required: false,
			default: null
		},
		
		disabled: {
			type: Boolean,
			required: false,
			default: false
		},
		
		disabledLabel: {
			type: [String, Object] as PropType<UILabelOptional>,
			required: false,
			default: null
		}
	})
	
	const emit = defineEmits([
		'update:modelValue'
	])
	
	const color = useColor(props)
	const log = useLogger('components/ui/signature/SignaturePad', useLogger.COLOR_COMPONENT)
	
	const mouse = reactive({
		current: cast<Position>({
			x: 0,
			y: 0
		}),
		previous: cast<Position>({
			x: 0,
			y: 0
		})
	})
	
	const $canvas = ref<HTMLCanvasElement|undefined>()
	
	const isSigning = ref(false)
	const isOverlayVisible = ref(true)
	const savedSignature = ref(props.modelValue || props.signature)
	
	const signatureWidth = ref(0)
	const signatureHeight = ref(0)
	
	const distanceDrawn = ref(0)
	
	// Signing itself
	// Euler's distance between two vectors, sqrt((x_1 - x_2)^2 + (y_1 - y_2)^2)
	const distance = (from: Position, to: Position) => Math.sqrt((from.x - to.x) ** 2 + (from.y - to.y) ** 2)
	
	const setupCanvas = () => {
		if (!$canvas.value) {
			log.always.warnFrom('setupCanvas', 'Could not set up canvas, element missing')
			return
		}
		
		const canvas = $canvas.value
		
		let scaleFactor = window.devicePixelRatio
		canvas.width = Math.ceil(canvas.getBoundingClientRect().width * scaleFactor)
		canvas.height = Math.ceil(canvas.getBoundingClientRect().height * scaleFactor)
		
		let ctx = canvas.getContext('2d')!
		ctx.clearRect(0, 0, canvas.width * scaleFactor, canvas.height * scaleFactor)
		ctx.scale(scaleFactor, scaleFactor)
		
		let img = new Image()
		img.src = savedSignature.value ?? ''
		
		img.onload = () => {
			ctx.drawImage(img, 0, 0, canvas!.width / scaleFactor, canvas!.height / scaleFactor)
			saveSignatureSize()
		}
	}
	
	const sign = () => {
		if (!$canvas.value) {
			log.always.warnFrom('sign', 'Could not sign, element missing')
			return
		}
		
		if (!isSigning.value || props.disabled) {
			log.infoFrom('sign', 'Signing disabled')
			return
		}
		
		const ctx = $canvas.value.getContext('2d')
		
		if (ctx) {
			ctx.strokeStyle = "#012265"
			ctx.lineWidth = 2
			ctx.imageSmoothingEnabled = true
			
			ctx.moveTo(mouse.previous.x, mouse.previous.y)
			ctx.lineTo(mouse.current.x, mouse.current.y)
			ctx.stroke()
			
			distanceDrawn.value += distance(mouse.previous, mouse.current)
			mouse.previous = mouse.current
		}
	}
	
	const onMouseDown = (e) => {
		if (props.disabled) {
			return
		}
		
		isOverlayVisible.value = false
		isSigning.value = true
		mouse.previous = getMousePosition(e)
	}
	
	const onTouchStart = (e) => {
		if (props.disabled) {
			return
		}
		
		lockScrolling()
		isOverlayVisible.value = false
		isSigning.value = true
		mouse.previous = getTouchPosition(e)
	}
	
	const onMouseMove = (e) => {
		mouse.current = getMousePosition(e)
		sign()
	}
	
	const onTouchMove = (e) => {
		mouse.current = getTouchPosition(e)
		sign()
	}
	
	const onMouseUp = () => {
		if (props.disabled) {
			return
		}
		
		isSigning.value = false
		saveSignature()
		emitValue()
	}
	
	const onTouchEnd = () => {
		if (props.disabled) {
			return
		}
		
		unlockScrolling()
		isSigning.value = false
		saveSignature()
		emitValue()
	}
	
	const getMousePosition = (e: MouseEvent) => {
		return {
			x: e.clientX - ($canvas.value?.getBoundingClientRect().left ?? 0),
			y: e.clientY - ($canvas.value?.getBoundingClientRect().top ?? 0),
		}
	}
	
	const getTouchPosition = (e: TouchEvent) => {
		return {
			x: e.touches[0].clientX - ($canvas.value?.getBoundingClientRect().left ?? 0),
			y: e.touches[0].clientY - ($canvas.value?.getBoundingClientRect().top ?? 0),
		}
	}
	
	const clearCanvas = () => {
		isOverlayVisible.value = true
		savedSignature.value = ''
		signatureWidth.value = 0
		signatureHeight.value = 0
		distanceDrawn.value = 0
		setupCanvas()
		
		if (!props.disabled) {
			emit('update:modelValue', null)
		}
		
		log.debugFrom('clearCanvas', 'Cleared')
	}
	
	const onScreenResize = () => {
		setupCanvas()
	}
	
	// Save Signature
	const saveSignature = () => {
		if (!$canvas.value) {
			log.always.warnFrom('saveSignature', 'Could not save, element missing')
			return
		}
		
		savedSignature.value = $canvas.value.toDataURL()
		saveSignatureSize()
	}
	
	const saveSignatureSize = () => {
		if (import.meta.env.ACQUISIT_MODE == 'production') {
			return
		}
		
		const size = getActualCanvasImageSize()
		
		if (!size) {
			return
		}
		
		signatureWidth.value = size.width
		signatureHeight.value = size.height
	}
	
	const getActualCanvasImageSize = () => {
		if (!$canvas.value) {
			log.always.warnFrom('getActualCanvasImageSize', 'Could not get size, element missing')
			return null
		}
		
		const canvas = $canvas.value
		const ctx = canvas.getContext('2d')
		
		if (!ctx) {
			log.always.warnFrom('getActualCanvasImageSize', 'Could not get 2d context')
			return
		}
		
		const pixels = ctx.getImageData(0, 0, canvas.width, canvas.height),
			length = pixels.data.length,
			bounds = {
				top: null as number|null,
				left: null as number|null,
				right: null as number|null,
				bottom: null as number|null
			}
		
		let x = 0,
			y = 0
		
		// Go over all the pixels and finds the image bounds
		for (let i = 0; i < length; i += 4) {
			if (pixels.data[i + 3] !== 0) {
				x = (i / 4) % canvas.width
				y = ~~((i / 4) / canvas.width)
				
				if (bounds.top === null) {
					bounds.top = y
				}
				
				if (bounds.left === null) {
					bounds.left = x
				} else if (x < bounds.left) {
					bounds.left = x
				}
				
				if (bounds.right === null) {
					bounds.right = x
				} else if (bounds.right < x) {
					bounds.right = x
				}
				
				if (bounds.bottom === null) {
					bounds.bottom = y
				} else if (bounds.bottom < y) {
					bounds.bottom = y
				}
			}
		}
		
		// Calculate size
		return {
			width: Number(bounds.right) - Number(bounds.left),
			height: Number(bounds.bottom) - Number(bounds.top),
			bounds
		}
	}
	
	const selectManualSignature = () => {
		isOverlayVisible.value = false
	}
	
	// Scroll Lock
	// Values to be restored by unlocking scrolling
	const scrollContainerBackup = ref<any>({})
	const preventDefault = (e) => e.preventDefault()
	
	const lockScrolling = () => {
		let $scrollContainer = document.querySelector('.page')
		
		if ($scrollContainer) {
			$scrollContainer.addEventListener('touchmove', preventDefault, { passive: false })
		} else {
			log.always.warnFrom('lockScrolling', 'Could not find scroll container')
		}
	}
	
	const unlockScrolling = () => {
		let $scrollContainer = document.querySelector('.page')
		
		if ($scrollContainer) {
			$scrollContainer.removeEventListener('touchmove', preventDefault)
		} else {
			log.always.warnFrom('unlockScrolling', 'Could not find scroll container')
		}
	}
	
	// Saving
	
	const emitValue = () => {
		if (props.disabled) {
			return
		}
		
		const signatureLargeEnough = (savedSignature.value ?? '').length > 10000 &&
		                             (signatureWidth.value > 0 ? signatureWidth.value >= 100 : true) &&
		                             (signatureHeight.value > 0 ? signatureHeight.value >= 100 : true) &&
		                             distanceDrawn.value >= 700
		
		if (savedSignature.value && !signatureLargeEnough) {
			emit('update:modelValue', 'invalid')
		} else {
			emit('update:modelValue', savedSignature.value || null)
		}
	}
	
	const saveCanvas = () => {
		if (props.disabled) {
			return
		}
		
		emit('update:modelValue', savedSignature.value)
	}
	
	watch(() => props.modelValue, (newValue, oldValue) => {
		if (newValue == oldValue || newValue == savedSignature.value || newValue == 'invalid') {
			return
		}
		
		savedSignature.value = newValue ?? ''
		
		if (String(newValue).substring(0, 5) == 'data:') {
			setupCanvas()
		} else {
			clearCanvas()
		}
	})
	
	useEventListener(window, 'resize', onScreenResize)
	
	onMounted(() => {
		setupCanvas()
		document.body.classList.add('fixed')
	})
	
	onBeforeUnmount(() => {
		document.body.classList.remove('fixed')
	})
	
	defineExpose({
		clearCanvas
	})
</script>

<style lang="scss" scoped>
	@import '@/assets/mixins.scss';

	#acquisit-signature-pad {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		flex-direction: column;
		position: relative;

		.canvas-wrapper {
			width: 100%;
			height: 252px; // Quote: But it's wanted from up high.. Plus 5(s) is not really a factor here in Norway yet..
			margin-bottom: 20px;
			position: relative;
			
			background: #f6f6f6;
			border-radius: 6px;
			transition: background 0.2s;

			@include media(mobile) {
				width: calc(100% + 2 * 24px);

				margin: {
					left: -24px;
					right: -24px;
				}
			}

			.canvas-underlay,
			.canvas-overlay {
				position: absolute;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				height: 100%;
				width: 100%;
		   	 	border-radius: 6px;
				
				> span.disabled,
				> span.placeholder {
					color: color("light-grey");
				}
			}
			
			.canvas-underlay {
				z-index: 2;
			}
			
			.canvas-overlay {
				z-index: 4;
			}

			canvas {
				position: relative;
				z-index: 3;
				
				width: 100%;
				height: 100%;
				
				&.disabled {
					pointer-events: none;
				}
			}
		}
	}
	
	@keyframes error {
		10%, 90% {
	    	transform: translate3d(-1px, 0, 0);
	  	}
	  
		20%, 80% {
			transform: translate3d(2px, 0, 0);
		}

		30%, 50%, 70% {
			transform: translate3d(-4px, 0, 0);
		}

		40%, 60% {
			transform: translate3d(4px, 0, 0);
		}
	}
</style>
