import Backend from '@/lib/backend/Backend'
import type { BankIDDataResponse, CreateImageCollectionResponse, CreateImageResponse, OrderResponse, UIResponse } from '@/lib/backend/types'
import type { AnyObject, Product } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { dataURIToBlob, generateID } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'

export default class Portal extends Backend {
	public readonly NAME: string = 'Portal'
	protected authorizationToken: string = ''
	
	public setAuthorizationToken(token: string): Portal {
		this.authorizationToken = token
		return this
	}
	
	public async getPreviewTemplateByToken(token: string): Promise<any> {
		const response = await this.get('/ui-builder/v1/preview/token/' + token, {}, false)
		
		return {
			template: JSON.parse(response.data.content),
			branding: response.data.branding,
			client: response.data.client,
		}
	}
	
	public async getPreviewTemplateForHistory(typeID: string|number, versionID: string|number, historyID: string|number): Promise<any> {
		const response = await this.get('/ui-builder/v1/types/' + typeID + '/versions/' + versionID + '/history/' + historyID)
		
		return {
			template: JSON.parse(response.data.content),
			branding: response.data.branding,
			client: response.data.client,
		}
	}
	
	public getBaseURL(opts) {
		return import.meta.env.ACQUISIT_PORTAL_BACKEND_URL
	}
	
	protected getHeaders(opts): {} {
		return {
			'Authorization': 'Bearer ' + this.authorizationToken
		}
	}
	
	public async createImageCollection(token: string, title: string | null, semantic: string | null, metadata: AnyObject | null): Promise<CreateImageCollectionResponse> {
		return {
			hash_id: generateID('image-collection-')
		}
	}
	
	public async uploadImage(token: string, imageCollectionID: string | number, image: string, title: string | null): Promise<CreateImageResponse> {
		return {
			hash_id: generateID('image-'),
			url: URL.createObjectURL(dataURIToBlob(image))
		}
	}
	
	public async deleteImage(token: string, imageCollectionID: string | number, imageID: string | number): Promise<void> {
		// do nothing
	}
	
	public async deleteImageCollection(token: string, imageCollectionID: string | number): Promise<void> {
		// do nothing
	}
	
	public async updateImageCollection(token: string, imageCollectionID: string | number, title: string | null, metadata: AnyObject | null): Promise<void> {
		// do nothing
	}
	
	public getBankIDURL(token: string, personID: string, returnURL: string): string {
		const url = new URL(window.location.href)
		url.pathname = '/bankid-dummy.html'
		url.searchParams.set('protocolUrl', returnURL)
		
		return url.href
	}
	
	public async getBankIDData(token: string, personID: string, sessionID: string): Promise<BankIDDataResponse> {
		return {
			common_name: 'Sample Person',
			person_number: '010490-12345',
			timestamp: Math.floor((+new Date()) / 1000)
		}
	}
	
	public getLatestBackup(token: string): Promise<AnyObject | null> {
		throw new Error('Not available')
	}
	
	public deleteBackup(token: string): Promise<void> {
		throw new Error('Not available')
	}
	
	public getOrderByTicket(ticketID: string, password: string): Promise<OrderResponse> {
		throw new Error('Not available')
	}
	
	public getOrderByToken(token: string): Promise<OrderResponse> {
		throw new Error('Not available')
	}
	
	public getProductTerms(token: string, product: Product): Promise<string | null> {
		throw new Error('Not available')
	}
	
	public getUI(token: string): Promise<UIResponse> {
		throw new Error('Not available')
	}
	
	public async storeBackup(token: string, backup: any): Promise<void> {
		// ignore
		console.info('Ignoring backup…')
	}
	
	public submitProtocol(token: string, payload): Promise<void> {
		throw new Error('Not available')
	}
	
	public submitReview(token: string, score: number, comment: string): Promise<void> {
		throw new Error('Not available')
	}
}