<template>
	<div class="summary-page">
		<acquisit-header v-if="header_text" :label="header_text">
			<!--
				TODO: Move the modal somewhere else, because header doesn't support children
			-->
			<acquisit-modal v-if="conditions_modal" class="conditions-modal" uid="conditions" :button="{ type: 'text', label: conditions_modal.title }" :modal="conditions_modal" />
		</acquisit-header>
		
		<acquisit-pages-error-summary v-if="summaryPage.shouldValidatePages.value" class="error-summary" />
		
		<ul v-if="!base.ui.hasChildren()">
			<li v-if="isReadyForSubmission" class="validated-wrapper">
				<v-svg class="icon" file="acq/document" :size="32" color="#717E8E" />
				
				<div class="message-wrapper">
					<acquisit-string class="page-title" :source="ready_text.title" />
					<acquisit-string class="page-message" :source="ready_text.body" />
				</div>
			</li>
		</ul>
		
		<div class="children" v-if="isReadyForSubmission && $slots.default">
			<slot></slot>
		</div>
		
		<div v-if="isReadyForSubmission" class="send-in-wrapper">
			<transition name="move-fade-top">
				<acquisit-info-box class="error-message offline" v-if="summaryPage.isOffline.value" :label="offline_text" type="error" />
			</transition>
			
			<acquisit-info-box v-if="apiStore.isPreview"
			                   class="error-message preview"
			                   label="Submissions are disabled in preview mode."
			                   type="plain" />
			
			<acquisit-button :class="['send-in-button', { pulsating: !summaryPage.isOffline.value && !apiStore.isPreview }]"
			                 uid="send-in-protocol"
			                 icon="arrows/right-thick"
			                 icon-position="right"
			                 data-tag="submit-button"
			                 :color="summaryPage.sendInButtonColor.value"
			                 type="pill"
			                 :bordered="summaryPage.isOffline.value || apiStore.isPreview || summaryPage.isSending.value"
			                 :no-interact="summaryPage.isOffline.value || apiStore.isPreview || summaryPage.isSending.value"
			                 :label="send_button_label"
			                 @click="sendIn" />
			
			<div class="content-summary-action" v-if="content_summary_label && !isContentSummaryOpen">
				<acquisit-button type="text"
				                 color="lightgreyblue"
				                 :label="content_summary_label"
				                 @click="openContentSummary" />
			</div>
		</div>
		
		<transition name="move-fade-top">
			<div class="content-summary-wrapper" v-if="isContentSummaryOpen">
				<acquisit-content-summary class="content-summary"
				                          :header="content_summary_header"
										  />
				
				<div class="send-in-wrapper">
					<acquisit-button :class="['send-in-button', { pulsating: !summaryPage.isOffline.value && !apiStore.isPreview }]"
					                 uid="send-in-protocol-2"
					                 icon="arrows/right-thick"
					                 icon-position="right"
					                 data-tag="submit-button-2"
					                 :color="summaryPage.sendInButtonColor.value"
					                 type="pill"
					                 :bordered="summaryPage.isOffline.value || apiStore.isPreview || summaryPage.isSending.value"
					                 :no-interact="summaryPage.isOffline.value || apiStore.isPreview || summaryPage.isSending.value"
					                 :label="send_button_label"
					                 @click="sendIn" />
				</div>
			</div>
		</transition>
	</div>
</template>

<script setup lang="ts">
	import { useBaseComponentProps, useBaseComponentEmits, useBaseComponent } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/helpers'
	import type { ModalCompound, UILabelOptional } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
	import { pageHasSemantic } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
	import { useSummaryPage, useSummaryPageProps } from '@/helpers/summary-page'
	import { computed, type PropType, ref, watchEffect } from 'vue'
	import { useProtocolStore } from '@/stores/protocol'
	import { useComponentsStore } from '@/stores/components'
	import { useAPIStore } from '@/stores/api'
	import { useRouter } from 'vue-router'
	import { useRaygun } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
	import { useUIStore } from '@/stores/ui'
	import { useBackend } from '@/lib/backend'
	import { errorMessage } from '@/lib/ui'
	
	const props = defineProps({
		...useBaseComponentProps(),
		...useSummaryPageProps(),
		
		/**
		 * A modal to show when the currently signed person ordered a product
		 * but provides no signature
		 */
		product_warning_modal: {
			type: Object as PropType<ModalCompound|null>,
			required: false,
			default: null
		},
		
		content_summary_label: {
			type: [String, Object] as PropType<UILabelOptional>,
			required: false,
			default: null
		},
		
		content_summary_header: {
			type: [String, Object] as PropType<UILabelOptional>,
			required: false,
			default: null
		},
	})
	
	const emit = defineEmits([
		...useBaseComponentEmits()
	])
	
	const base = useBaseComponent(props, emit)
	const summaryPage = useSummaryPage(props)
	const $router = useRouter()
	
	const { online } = summaryPage
	
	const protocolStore = useProtocolStore()
	const componentsStore = useComponentsStore()
	const apiStore = useAPIStore()
	const uiStore = useUIStore()
	
	const isContentSummaryOpen = ref(false)
	const isSending = ref(false)
	const submissionsCount = ref(0)
	
	const openContentSummary = () => {
		isContentSummaryOpen.value = true
	}
	
	const sendIn = (e) => {
		useRaygun().recordBreadcrumb('Click Submit', {
			data: {
				eventType: e.type,
				count: submissionsCount.value
			},
			location: 'SummaryPage.vue:sendIn'
		})
		
		if (!online.isOnline.value || apiStore.isPreview || isSending.value) {
			return false
		}
		
		isSending.value = true
		uiStore.setLoader('Sender inn…')
		
		setTimeout(async () => {
			try {
				if (submissionsCount.value > 0) {
					// nope!
				} else {
					await protocolStore.submit(apiStore.token!)
				}
				
				submissionsCount.value += 1
			} catch (e: any) {
				if (e?.response?.status == 409) {
					// Ignore
				} else if (e?.response?.status == 499) {
					useRaygun().send(e)
					await errorMessage('There was an error submitting your protocol. Please try again.', 'Network Error (499)')
					return
				} else {
					useRaygun().send(e)
					useBackend().handleError(e)
					return
				}
			}
			
			try {
				await $router.push({
					name: 'outro'
				})
			} catch (e: any) {
				// shut up, Vue Router
				useRaygun().send(e)
			} finally {
				uiStore.setLoader(false)
				isSending.value = false
			}
		}, 200)
	}
	
	const isValidated = ref(false)
	const isReadyForSubmission = computed(() => !summaryPage.shouldValidatePages.value || isValidated.value)
	
	watchEffect(() => {
		isValidated.value = true
		
		for (let page of protocolStore.pages) {
			if (page.enabled && !page.data.validated && !pageHasSemantic(page, 'summary')) {
				isValidated.value = false
				break
			}
		}
	})
	
	const signaturePage = computed(() => {
		const signaturePages = componentsStore.by_tag['signature-page']
		
		if (Array.isArray(signaturePages) && signaturePages.length) {
			return signaturePages[0]
		}
		
		return null
	})
	
	defineExpose({
		...base.expose
	})
</script>

<style lang="scss" scoped>
	@import '@/assets/mixins.scss';
	
	.checkbox-wrapper {
		padding: 26px 52px;
		margin-bottom: -26px;
		border-top: 1px solid #e0e0e0;
		border-bottom: 1px solid #e0e0e0;
		
		.modal {
			margin: 0 26px;
		}
	}

	.summary-page {
		display: flex;
		height: 100%;
		width: 100%;
		flex-direction: column;
		color: rgba(0, 0, 0, .87);
		
		ul, .error-summary {
			margin: {
				top: 12px;
				left: -24px;
				right: -24px;
			}
		}

		ul {
			box-sizing: border-box;
			justify-content: center;
			width: calc(100% + 2 * 24px);
			
			margin: {
				top: 16px;
				left: -24px;
				right: -24px;
			}

			li {
				transition: background 0.2s, color 0.2s;
				
				display: flex;
				margin: auto;
				justify-content: flex-start;
				align-items: center;
				cursor: pointer;
				border-bottom: 1px solid #e0e0e0;
				
				padding: {
					top: 16px;
					bottom: 16px;
					left: 24px;
					right: 32px;
				}

				.icon {
					width: 42px;
					margin-right: 16px;
					border-radius: 100%;
				}

				&:first-child {
					border-top: 1px solid #e0e0e0;
				}
				
				&:hover {
					background: color("light-background");
				}
				
				.page-action {
					width: 42px;
					text-align: right;
					font-size: 24px;
					font-weight: 900;
				}
			}

			.validated-wrapper {
				cursor: default;
				pointer-events: none;
				
				margin: {
					bottom: 32px;
				}
			}
		}
		
		.children {
			margin-bottom: 24px;
		}
		
		.message-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: center;
			line-height: 1.3;
			width: calc(100% - 2 * 42px);

			.page-title {
				font-weight: 600;
				font-size: 16px;
				color: #222222;
			}
			
			.page-title + .page-message {
				margin-top: 2px;
			}

			.page-message {
				font-size: 14px;
				color: color("light-grey")
			}
		}

		.send-in-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			
			.error-message {
				margin-bottom: 20px;
				width: calc(100% - 2 * 24px);
			}

			&:deep(.send-in-button) {
				padding-left: 32px;
				padding-right: 32px;
				
				.acquisit-string {
					font-size: 20px;
					font-weight: 700;
				}
				
				&.pulsating {
					animation-name: scale;
					animation-duration: 2s;
					animation-iteration-count: infinite;
					animation-timing-function: ease-in-out;
				}
			}
			
			.content-summary-action {
				margin-top: 32px;
			}
		}
		
		.content-summary-wrapper {
			margin-top: 36px;
			
			.send-in-wrapper {
				margin: {
					top: 32px;
					bottom: 64px + 32px;
				}
			}
		}

		.modal-buttons {
			display: flex;
			justify-content: flex-end;
		}
	}
	
	.error-modal {
		
		pre.stacktrace {
			white-space: pre;
			tab-size: 4;
			font-family: font(monospace);
			font-size: 14px;
			line-height: 1.5;
			overflow-x: auto;
		}
	}
	
	.conditions-modal {
		display: inline;
		text-align: left;
		
		:deep(.open-btn) {
			font-size: 18px;
		}
	}

	@keyframes scale {
		0% {
			transform: scale(1);
		}
		
		50% {
			transform: scale(1.2);
		}
		
		100% {
			transform: scale(1);
		}
	}
</style>
