import type { Component, Intro, Page, Person, Product, ProductCheckboxCompound, UILabelOptional } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { parseSignature } from '@/stores/persons'
import { fetchImageAsDataURI, trimEmojis, trimHTML, pageHasSemantic } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
import { useLogger, Benchmark, getLanguageLabelFor } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { loadBuiltInIcon } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/icons'

window.__dirname = '/'

// PDF Stuff
import PDFDocument from 'pdfkit'
import blobStream from 'blob-stream'

import MarkdownIt from 'markdown-it'
import { renderComponents } from '@/lib/pdf/renderers'
import fs from 'fs'
// @ts-ignore
import TextOptions = PDFKit.Mixins.TextOptions
// @ts-ignore
import ImageOption = PDFKit.Mixins.ImageOption
import { isParsedBankIDSignature, isParsedImageSignature } from '@/lib/types'

// Icons
import CheckboxMarkedGreenIcon from '@/assets/icons/pdf/checkbox-marked-green.svg?raw'
import CheckboxIcon from '@/assets/icons/pdf/checkbox.svg?raw'

const log = useLogger('lib/pdf', 'magenta')

// Load fonts for PDF
function registerAFMFonts(fonts: Record<string, () => Promise<any>> ) {
	if (fs.existsSync && !fs.existsSync('data')) {
		fs.mkdirSync('data')
	}
	
	for (const path in fonts) {
		const match = path.match(/([^/]*\.afm$)/)
		
		if (match) {
			fonts[path]().then(data => {
				fs.writeFileSync(`data/${match[0]}`, data)
			})
		} else {
			log.warn('Could not load font: ' + path)
		}
	}
}

const fonts = import.meta.glob('../../../node_modules/pdfkit/js/data/Helvetica*.afm', { query: '?raw', import: 'default' })
registerAFMFonts(fonts)

// Define Markdown parser
const markdown = new MarkdownIt({
	html: true
})

export interface PDFKitDocumentAdditions {
	openImage(src)
}

export type PDFKitDocument = typeof PDFDocument & PDFKitDocumentAdditions

/**
 * Convert an SVG string into a PNG image data URI
 *
 * @param {string} svg
 *
 * @returns {Promise<string>} PNG data URI
 */
export const svg2png = async (svg: string): Promise<string> => new Promise((resolve, reject) => {
	// Render SVG into canvas as PNG
	let image = new Image(),
		canvas: HTMLCanvasElement|null = null,
		context: CanvasRenderingContext2D|null = null,
		normalizedSVG = svg
	
	if (!/<svg .*?(width|height)/.test(normalizedSVG)) {
		if (normalizedSVG.indexOf('viewBox="')) {
			normalizedSVG = normalizedSVG.replace(/<svg( .*?) (viewBox="([\d.+]) ([\d.]+) ([\d.]+) ([\d.]+)")/s, '<svg$1 $2 width="$5px" height="$6px"')
		} else {
			reject(new Error('Could not find viewBox on SVG ' + svg))
			return
		}
	}
	
	if (normalizedSVG.substring(0, 9) != '<!DOCTYPE' && normalizedSVG.substring(0, 5) != '<?xml') {
		normalizedSVG = '<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">' + normalizedSVG
	}
	
	image.src = 'data:image/svg+xml,' + encodeURIComponent(normalizedSVG)
	
	image.onerror = e => reject(e)
	image.onload = _ => {
		canvas = document.createElement('canvas')
		canvas.width = image.naturalWidth
		canvas.height = image.naturalHeight
		
		context = canvas.getContext('2d')
		context!.drawImage(image, 0, 0)
		
		resolve(canvas.toDataURL('image/png'))
	}
})

/**
 * Fetch an image from a URL
 * If the URL leads to an SVG it will be converted to PNG at its viewBox or defined size.
 *
 * @param {string} url  URL to fetch from, must support CORS
 *
 * @returns {Promise<string>} PNG data URI (with base64), e.g. `data:image/png;base64,…`
 */
export const fetchImage = async (url: string): Promise<string> => new Promise(async (resolve, reject) => {
	try {
		const response = await fetch(url, {
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'omit'
		})
		
		if (response.headers.get('Content-Type') == 'image/svg+xml') {
			const png = await svg2png(await response.text())
			resolve(png)
		} else {
			const blob = await response.blob()
			const reader = new FileReader()
			
			reader.onload = e => resolve(e.target!.result as string)
			reader.onerror = e => reject(e)
			
			reader.readAsDataURL(blob)
		}
	} catch (e: any) {
		reject(e)
	}
})

export const fetchIcon = async (path: string): Promise<string> => {
	const [ pack, icon ] = String(path).split('/')
	let svg: string
	
	// Try to fetch locally first
	try {
		svg = (await import(`../../assets/icons/${pack}/${icon}.svg?raw`)).default
	} catch (e: any) {
		// Try AcquisitUI icon
		svg = (await loadBuiltInIcon(pack, icon))
	}
	
	return svg2png(svg)
}

/**
 * A section in the PDF
 */
export interface Section {
	/**
	 * The title of the section
	 */
	title: string
	/**
	 * The page from the protocol associated with this section
	 */
	uiPage: Page
	/**
	 * All screenshots available for this section
	 */
	screenshots: string[]
}

export type Position = {x: number, y: number}

export type PDFUILabelParser = (label: UILabelOptional) => string | null | undefined

/**
 * PDF protocol render helper
 *
 * @author bluefirex
 * @date 2020-04-28, 2022-05-23
 * @version 1.0
 */
export class PDF {
	public pdf: PDFKitDocument
	protected logoDataURI: string|null
	protected labelParser: PDFUILabelParser
	protected stream
	protected currentSection: Section|null = null
	public style
	public colors
	public fonts
	public sizes
	
	private imageCache: Record<string, string> = {}
	
	/**
	 * @param {PDFUILabelParser}    labelParser     Parser for labels
	 * @param {string|null}         logoDataURI
	 */
	constructor(
		labelParser: PDFUILabelParser,
		logoDataURI: string|null
	) {
		// Create the PDF instance
		this.pdf = (new PDFDocument()) as PDFKitDocument
		// Pipe it through a blob stream, so we can retrieve it as a blob at the end to convert it into a data URI
		this.stream = this.pdf.pipe(blobStream())
		// Data URI of the client's logo
		this.logoDataURI = logoDataURI == 'data:,' ? null : logoDataURI
		// Bind label parser
		this.labelParser = labelParser
		
		// Ensure client logo on every page
		this.pdf.on('pageAdded', () => {
			let x = this.x,
				y = this.y
			
			if (this.logoDataURI) {
				let logo = this.pdf.openImage(this.logoDataURI),
					width = this.pageWidth - 2 * this.sizes.headerX,
					height = Math.min(24, logo.height)
				
				this.pdf.image(logo, this.sizes.headerX, this.sizes.headerY, {
					fit: [width, height],
					valign: 'center',
					align: 'right'
				})
				
				this.moveCursorTo(x, y)
			}
		})
		
		// Basic styling
		this.style = {
			headerX: 40,
			headerY: 32,
			
			textCentered: {
				width: this.pdf.page.width,
				align: 'center'
			}
		}
		
		this.colors = {
			text: '#222222',
			subtext: '#888888',
			stroke: '#e0e0e0',
			green: '#4CAF50'
		}
		
		this.fonts = {
			standard: 'Helvetica',
			bold: 'Helvetica-Bold'
		}
		
		this.sizes = {
			signatureStroke: 1,
			signatureStrokePadding: 8,
			
			bodyFont: 11,
			headerFont: 14,
			smallFont: 10,
			headerX: 40,
			headerY: 32
		}
	}
	
	public parseLabel(label: UILabelOptional): string | undefined | null {
		return this.labelParser(label)
	}
	
	/**
	 * Get the current horizontal position of the internal cursor
	 *
	 * @returns {number}
	 */
	get x(): number {
		return this.pdf.x
	}
	
	/**
	 * Get the current vertical position of the internal cursor
	 *
	 * @returns {number}
	 */
	get y(): number {
		return this.pdf.y
	}
	
	/**
	 * Get the position of the internal cursor
	 * @returns {Position}
	 */
	get cursor(): Position {
		return {
			x: this.x,
			y: this.y
		}
	}
	
	/**
	 * Basic document setup
	 */
	setup(): this {
		this.pdf.info.Producer = this.pdf.info.Creator = 'Visma Real Estate AS'
		this.pdf.font(this.fonts.standard)
		this.pdf.fontSize(this.sizes.bodyFont)
		return this
	}
	
	/**
	 * Add a blank page at the end
	 */
	addPage(): this {
		this.pdf.addPage()
		return this
	}
	
	/**
	 * Apply the header font
	 *
	 * @param {number} size Defaults to sizes.headerFont
	 * @returns {this}
	 */
	setHeaderFont(size: number = this.sizes.headerFont): this {
		this.pdf.fillColor(this.colors.text)
		this.pdf.font(this.fonts.standard)
		this.pdf.fontSize(size)
		return this
	}
	
	/**
	 * Apply the body font
	 *
	 * @param {number} size Defaults to sizes.bodyFont
	 * @returns {this}
	 */
	setBodyFont(size: number = this.sizes.bodyFont): this {
		this.pdf.fillColor(this.colors.text)
		this.pdf.font(this.fonts.standard)
		this.pdf.fontSize(size)
		return this
	}
	
	/**
	 * Apply the bold font
	 *
	 * @param {number} size Defaults to sizes.boldFont
	 * @returns {this}
	 */
	setBoldFont(size: number = this.sizes.bodyFont): this {
		this.pdf.fillColor(this.colors.text)
		this.pdf.font(this.fonts.bold)
		this.pdf.fontSize(size)
		return this
	}
	
	/**
	 * Apply the subtext font
	 *
	 * @param {number} size Defaults to sizes.bodyFont
	 * @returns {this}
	 */
	setSubtextFont(size: number = this.sizes.bodyFont): this {
		this.pdf.fillColor(this.colors.subtext)
		this.pdf.font(this.fonts.standard)
		this.pdf.fontSize(size)
		return this
	}
	
	/**
	 * Draw some text at the internal cursor position
	 *
	 * @param {string}                      text        Text to draw
	 * @param {PDFKit.Mixins.TextOptions}   options     PDFKit TextOptions
	 * @returns {PDFKitDocument}
	 */
	drawText(text: string, options: TextOptions|null = null): PDFKitDocument {
		return this.pdf.text(text, {
			lineGap: 6,
			...options
		})
	}
	
	/**
	 * Draw some text at a specific location
	 *
	 * @param {Position}                    position    Position to draw at
	 * @param {string}                      text        Text to draw
	 * @param {PDFKit.Mixins.TextOptions}   options     PDFKit TextOptions
	 *
	 * @returns {PDFKitDocument}
	 */
	drawTextAt(position: Position, text: string, options?: TextOptions): PDFKitDocument {
		return this.pdf.text(text, position.x, position.y, options)
	}
	
	/**
	 * Draw some text at a specific x location, using the internal y position
	 *
	 * @param {number}                      x           X position
	 * @param {string}                      text        Text to draw
	 * @param {PDFKit.Mixins.TextOptions}   options     PDFKt TextOptions
	 *
	 * @returns {PDFKitDocument}
	 */
	drawTextAtX(x: number, text: string, options?: TextOptions): PDFKitDocument {
		return this.pdf.text(text, x, this.pdf.y, options)
	}
	
	/**
	 * Draw some text at a specific y location, using the internal x position
	 *
	 * @param {number}                      y           Y position
	 * @param {string}                      text        Text to draw
	 * @param {PDFKit.Mixins.TextOptions}   options     PDFKt TextOptions
	 *
	 * @returns {PDFKitDocument}
	 */
	drawTextAtY(y: number, text: string, options?: TextOptions): PDFKitDocument {
		return this.pdf.text(text, this.pdf.x, y, options)
	}
	
	/**
	 * Draw a line
	 *
	 * @param {Position}    from    Start position
	 * @param {Position}    to      End position
	 * @param {string}      color   Color of the line, defaults to colors.stroke
	 *
	 * @returns {PDFKitDocument}
	 */
	drawLine(from: Position, to: Position, color: string = this.colors.stroke): PDFKitDocument {
		let previousX = this.pdf.x,
			previousY = this.pdf.y
		
		return this.pdf
            .moveTo(from.x, from.y)
			.lineTo(to.x, to.y)
			.stroke(color)
			.moveTo(previousX, previousY)
			.moveDown()
	}
	
	drawImageAt(position: Position, image: string, options: ImageOption = {}) {
		try {
			const file = this.pdf.openImage(image)
			
			this.pdf.image(file, position.x, position.y, {
				...options
			})
		} catch (e) {
		    log.always.error('[pdf.ts, drawImageAt]', 'Error drawing image', e)
		}
	}
	
	/**
	 * Move the internal cursor to a specific position
	 *
	 * @param {number} x
	 * @param {number} y
	 */
	moveCursorTo(x: number, y: number)
	/**
	 * Move the internal cursor to a specific position
	 *
	 * @param {Position} position
	 */
	moveCursorTo(position: Position)
	moveCursorTo(arg1, arg2?): this {
		if (typeof(arg1) == 'object') {
			this.moveCursorTo(arg1.x, arg1.y)
		} else {
			this.pdf.x = arg1
			this.pdf.y = arg2
		}
		
		return this
	}
	
	/**
	 * Move the cursor down by a number of line
	 *
	 * @param {number}  lines   Defaults to 1
	 *
	 * @returns {this}
	 */
	moveCursorDown(lines: number = 1): this {
		this.pdf.moveDown(lines)
		return this
	}
	
	/**
	 * Move the cursor down by a number of dots
	 *
	 * @param {number}  y   Dots
	 *
	 * @returns {this}
	 */
	moveCursorDownBy(y: number): this {
		this.pdf.y += y
		return this
	}
	
	/**
	 * Move the cursor right by a number of dots
	 *
	 * @param {number}  x   Dots
	 *
	 * @returns {this}
	 */
	moveCursorRightBy(x: number): this {
		this.pdf.x += x
		return this
	}
	
	/**
	 * Get the document's page width in points
	 */
	get pageWidth(): number {
		return this.pdf.page.width
	}
	
	/**
	 * Get the document's page height in points
	 */
	get pageHeight(): number {
		return this.pdf.page.height
	}
	
	/**
	 * Add the intro page to the document
	 *
	 * @param {Intro}  intro     Protocol Intro to use texts from
	 */
	addIntro(intro: Intro): this {
		this.pdf.font(this.fonts.standard)
		this.pdf.fontSize(18)
		this.pdf.fillColor(this.colors.text)
		
		this.pdf.text(this.parseLabel(intro.subject || '') ?? '', 0, this.pageHeight / 2 - 32, this.style.textCentered)
		
		this.pdf.fontSize(12)
		this.pdf.fillColor(this.colors.subtext)
		
		let date = new Date(),
			dateStr = String(date.getDate()).padStart(2, '0') + '.' +
			          String(date.getMonth() + 1).padStart(2, '0') + '.' +
			          String(date.getFullYear()).padStart(4, '0')
		
		this.pdf.text(dateStr, 0, this.pageHeight / 2, this.style.textCentered)
		
		if (this.logoDataURI) {
			let logo = this.pdf.openImage(this.logoDataURI),
				width = this.pageWidth,
				height = Math.min(36, logo.height)
			
			this.pdf.image(logo, 0, this.sizes.headerY, {
				fit: [width, height],
				valign: 'center',
				align: 'center'
			})
		}
		
		return this
	}
	
	/**
	 * Draw a key-value label
	 * That's a two-column textbox with one column containing the key at 33% width and the other containing the label.
	 *
	 * @param {string}                      key     Key (first column)
	 * @param {string}                      label   Label (second column)
	 * @param {PDFKit.Mixins.TextOptions}   options PDFKit TextOptions
	 * @returns {this}
	 */
	drawKeyValueLabel(key: string, label: string, options?: TextOptions): this {
		let beginY = this.y
		
		let totalWidth = (this.pageWidth - this.sizes.headerX * 2),
			labelWidth = totalWidth / 3,
			valueWidth = totalWidth - labelWidth
		
		this.drawText(key, {
			width: labelWidth,
			...options,
		})
		
		let supposedY = this.y
		
		this.drawTextAt({
			x: this.sizes.headerX + labelWidth,
			y: beginY
		}, label, {
			width: valueWidth,
			...options,
		})
		
		this.moveCursorTo(this.sizes.headerX, supposedY)
		
		return this
	}
	
	/**
	 * Draw a section in the PDF
	 *
	 * @param {Section} section
	 *
	 * @returns {Promise<void>}
	 */
	public async drawSection(section: Section): Promise<void> {
		this.currentSection = section
		
		let benchmark = Benchmark.start(section.title)
		log.debug('drawSection', section)
		
		// Draw header
		this.setHeaderFont()
		this.moveCursorTo(this.sizes.headerX, this.sizes.headerY + 10)
		this.pdf.text(section.title)
		this.setBodyFont()
		
		let defaultRender = true
		
		if (pageHasSemantic(section.uiPage, 'product')) {
			let didRender = await this.drawProductPageSection(section)
			
			if (didRender) {
				defaultRender = false
			}
		} else if (pageHasSemantic(section.uiPage, 'parsed-pdf')) {
			let didRender = await this.drawParsedPageSection(section)
			
			if (didRender) {
				defaultRender = false
			}
		} else if (pageHasSemantic(section.uiPage, 'content-summary')) {
			// Ignore this page
			defaultRender = false
		}
		
		if (defaultRender) {
			if (section.screenshots.length) {
				for (let screenshot of section.screenshots) {
					await this.drawScreenshot(screenshot)
				}
			} else {
				// Nothing to render ¯\_(ツ)_/¯
			}
		}
		
		benchmark.end(true)
		this.currentSection = null
	}
	
	protected findProductChooserOnPage(page: Page): Component|undefined {
		for (let component of page.components) {
			if (component.tag_normalized == 'single-product-chooser-carousel' || component.tag_normalized == 'single-product-chooser-list') {
				return component
			}
		}
		
		return undefined
	}
	
	/**
	 * Draw a product page section in the PDF
	 *
	 * @param {Section} section
	 *
	 * @returns {Promise<boolean>}
	 * @protected
	 */
	protected async drawProductPageSection(section: Section): Promise<boolean> {
		let didRender = false
		
		// Draw the selected product or a note if there was none selected
		// Find out whether there was a selection
		let chooserComponent = this.findProductChooserOnPage(section.uiPage)
		
		// We found a component to work with!
		if (chooserComponent) {
			// defaultRender = false
			if (chooserComponent.properties.declined) {
				didRender = true
				
				this.pdf.fontSize(this.sizes.bodyFont)
				this.pdf.text('Intet produkt bestilt', 0, this.pageHeight / 2, this.style.textCentered)
				this.pdf.fontSize(this.sizes.headerFont)
				
				this.addPage()
			} else if (chooserComponent.properties.already_ordered) {
				didRender = true
				
				this.pdf.fontSize(this.sizes.bodyFont)
				this.pdf.text('Kjøper fant ikke ønsket leverandør på denne siden', 0, this.pageHeight / 2, this.style.textCentered)
				this.pdf.fontSize(this.sizes.headerFont)
				
				this.addPage()
			} else {
				let selectedProductID = chooserComponent.properties.modelValue?.[0],
					selectedProduct: Product|null = null
				
				if (selectedProductID !== null) {
					for (let product of chooserComponent.properties.products) {
						if (product.uid == selectedProductID) {
							selectedProduct = product
							break
						}
					}
				}
				
				if (selectedProduct) {
					try {
						let logoFile = await fetchImage(selectedProduct.logo!)
						
						let logo = this.pdf.openImage(logoFile),
							marginTop = this.sizes.headerY + 32,
							centeredTextStyle: TextOptions = {
								width: this.pdf.page.width - 2 * this.sizes.headerX,
								align: 'center'
							}
						
						// Draw header, if any
						if (chooserComponent.properties.header) {
							// Replace <br /> with \n
							let header = this.parseLabel(chooserComponent.properties.header)!.replace(/<br \/>/g, "\n")
							
							// Strip remaining HTML tags
							header = this.trimStringForDisplay(header)
							
							marginTop += 16
							
							this.pdf.fontSize(this.sizes.bodyFont)
							this.pdf.text(header, this.sizes.headerX, marginTop, centeredTextStyle)
							marginTop += 24
						}
						
						// Draw logo
						this.pdf.image(logo, this.pageWidth / (2 * 2), marginTop, {
							fit: [this.pageWidth / 2, 128],
							valign: 'center',
							align: 'center'
						})
						
						marginTop += 128 + 20
						
						// Draw Product Title
						this.pdf.fontSize(this.sizes.headerFont)
						
						if (selectedProduct.title) {
							this.pdf.text(this.trimStringForDisplay(selectedProduct.title), this.sizes.headerX, marginTop, centeredTextStyle)
							marginTop += 24
						}
						
						// Draw more product info
						let optionalKeys = [
							'description',
							'list',
							'generic_text',
							'fineprint',
							'footer'
						]
						
						this.pdf.fontSize(this.sizes.bodyFont)
						
						for (let key of optionalKeys) {
							if (selectedProduct[key]) {
								if (key == 'list' && Array.isArray(selectedProduct[key]) && selectedProduct[key]?.length) {
									this.pdf.fontSize(this.sizes.smallFont)
									marginTop += 4
									
									for (let item of selectedProduct[key] ?? []) {
										let clean = '– ' + this.trimStringForDisplay(item)
										
										this.pdf.text(clean, this.sizes.headerX, marginTop, centeredTextStyle)
										marginTop = this.pdf.y + 4
									}
									
									marginTop += 8
								} else {
									this.pdf.fontSize(this.sizes.bodyFont)
									
									let clean = this.trimStringForDisplay(selectedProduct[key])
									
									this.pdf.text(clean, this.sizes.headerX, marginTop, centeredTextStyle)
									marginTop = this.pdf.y + 8
								}
							}
						}
						
						// Draw footer, if any
						if (chooserComponent.properties.footer) {
							// Replace <br /> with \n
							let footer = chooserComponent.properties.footer.replace(/<br \/>/g, "\n")
							
							// Strip remaining HTML tags
							footer = this.trimStringForDisplay(footer)
							
							marginTop = this.pdf.y + 16
							this.pdf.fontSize(this.sizes.smallFont)
							this.pdf.text(footer, this.sizes.headerX, marginTop, centeredTextStyle)
						}
						
						// Draw terms checkbox, if any
						if (Array.isArray(selectedProduct.checkboxes)) {
							marginTop = await this.drawProductCheckboxes(selectedProduct)
						}
						
						// Old style of terms and angrerett
						if (selectedProduct.terms?.checkbox_label && selectedProduct.terms_agreed) {
							marginTop = await this.drawProductTerms(selectedProduct)
						}
						
						if (chooserComponent.properties.accepted_label) {
							marginTop = this.pdf.y + 24
							
							// Draw line
							this.drawLine({
								x: this.sizes.headerX,
								y: marginTop
							}, {
								x: this.pageWidth - this.sizes.headerX,
								y: marginTop
							})
							
							marginTop = this.pdf.y + 32
							
							// Draw label
							const label = getLanguageLabelFor(chooserComponent.properties.accepted_label, undefined)!
							
							this.pdf.fillColor(this.colors.green)
							this.pdf.font(this.fonts.bold)
							this.pdf.text(label, this.sizes.headerX + 16 + 4, marginTop)
							
							// Draw checkbox
							let checkboxMarkedIconPNG = await svg2png(CheckboxMarkedGreenIcon)
							
							this.drawImageAt({
								x: this.sizes.headerX,
								y: marginTop - 4
							}, checkboxMarkedIconPNG, {
								width: 16,
								height: 16
							})
							
							marginTop = this.pdf.y
						}
						
						// Reset to defaults
						this.pdf.fontSize(this.sizes.headerFont)
						this.pdf.fillColor(this.colors.text)
						
						// We're good!
						didRender = true
						this.addPage()
					} catch (e) {
						log.always.warn('Unable to render product, falling back to default…', e)
						didRender = false
						this.addPage()
					}
				} else {
					// Otherwise, fall back to default rendered
					log.always.info('No selected product or logo, falling back to default…')
					didRender = false
					this.addPage()
				}
			}
		}
		
		return didRender
	}
	
	/**
	 * Draw all checkboxes for a product
	 *
	 * @param {Product} product Product to draw
	 *
	 * @returns {number} new marginTop
	 * @private
	 */
	private async drawProductCheckboxes(product: Product): Promise<number> {
		if (!Array.isArray(product.checkboxes)) {
			return this.pdf.y
		}
		
		let marginTop = this.pdf.y + 24
		
		// Divider between product and its checkboxes
		this.drawLine({
			x: this.sizes.headerX,
			y: marginTop
		}, {
			x: this.pageWidth - this.sizes.headerX,
			y: marginTop
		})
		
		marginTop = this.pdf.y + 32
		
		for (let checkbox of product.checkboxes) {
			marginTop = await this.drawProductCheckbox(checkbox, product.checkboxes_values?.[checkbox.uid] ?? false, marginTop)
		}
		
		return marginTop
	}
	
	/**
	 * Draw a single product checkbox
	 *
	 * @param {ProductCheckboxCompound} checkbox    Checkbox to draw
	 * @param {boolean}                 value       Whether the checkbox is checked
	 * @param {number}                  marginTop   Top margin from where to begin drawing
	 *
	 * @returns {Promise<number>} new marginTop
	 * @private
	 */
	private async drawProductCheckbox(checkbox: ProductCheckboxCompound, value: boolean, marginTop: number): Promise<number> {
		// Draw checkbox
		let checkboxIcon: string
		
		if (value) {
			checkboxIcon = await this.getCheckboxMarkedIcon()
		} else {
			checkboxIcon = await this.getCheckboxIcon()
		}
		
		this.drawImageAt({
			x: this.sizes.headerX,
			y: marginTop - 4
		}, checkboxIcon, {
			width: 16,
			height: 16
		})
		
		// Draw label
		let checkboxLabel = this.trimStringForDisplay(getLanguageLabelFor(checkbox.label, undefined)!)
		
		this.pdf.fillColor(this.colors.text)
		this.pdf.fontSize(this.sizes.smallFont)
		this.pdf.text(checkboxLabel, this.sizes.headerX + 16 + 4, marginTop)
		
		marginTop = this.pdf.y + 8
		
		// Draw actions
		if ((checkbox.actions ?? []).length) {
			let actionsX = this.sizes.headerX + 16 + 4
			
			this.pdf.fillColor(this.colors.subtext)
			this.pdf.fontSize(this.sizes.smallFont - 1)
			
			for (let action of checkbox.actions ?? []) {
				const actionLabel = this.trimStringForDisplay(getLanguageLabelFor(action.label, undefined)!)
				
				if (!actionLabel) {
					continue
				}
				
				this.pdf.text(actionLabel, actionsX, marginTop, {
					continued: true,
					underline: true
				})
				
				this.pdf.text('  ', actionsX, marginTop, {
					continued: true,
					underline: false
				})
			}
			
			this.pdf.text('', {
				continued: false,
				underline: false
			})
			
			marginTop = this.pdf.y + 16 + 4
		} else {
			marginTop = this.pdf.y
		}
		
		return marginTop
	}
	
	/** @deprecated Legacy style */
	private async drawProductTerms(selectedProduct: Product): Promise<number> {
		if (!selectedProduct.terms) {
			return this.pdf.y
		}
		
		let marginTop = this.pdf.y + 24
		let checkboxContent = this.trimStringForDisplay(getLanguageLabelFor(selectedProduct.terms.checkbox_label, undefined)!)
		
		this.drawLine({
			x: this.sizes.headerX,
			y: marginTop
		}, {
			x: this.pageWidth - this.sizes.headerX,
			y: marginTop
		})
		
		marginTop = this.pdf.y + 32
		
		// Draw checkmark
		let checkboxMarkedIconPNG = await this.getCheckboxMarkedIcon()
		
		this.drawImageAt({
			x: this.sizes.headerX,
			y: marginTop - 4
		}, checkboxMarkedIconPNG, {
			width: 16,
			height: 16
		})
		
		// Draw label
		this.pdf.fontSize(this.sizes.smallFont)
		this.pdf.text(checkboxContent, this.sizes.headerX + 16 + 4, marginTop)
		
		marginTop = this.pdf.y + 8
		
		if (selectedProduct.terms.terms_modal || selectedProduct.terms.withdrawal_modal || selectedProduct.terms.withdrawal_form_label) {
			let linksX = this.sizes.headerX + 16 + 4
			
			this.pdf.fillColor(this.colors.subtext)
			this.pdf.fontSize(this.sizes.smallFont - 1)
			
			if (selectedProduct.terms.terms_modal) {
				const openTermsModalLabel = getLanguageLabelFor(selectedProduct.terms.terms_modal.button_open_label, undefined)!
				
				this.pdf.text(openTermsModalLabel, linksX, marginTop, {
					continued: true,
					underline: true
				})
				
				this.pdf.text('   ', {
					continued: true,
					underline: false
				})
			}
			
			if (selectedProduct.terms.withdrawal_form_label) {
				const withdrawalFormLabel = getLanguageLabelFor(selectedProduct.terms.withdrawal_form_label, undefined)!
				
				this.pdf.text(withdrawalFormLabel, linksX, marginTop, {
					continued: true,
					underline: true
				})
				
				this.pdf.text('   ', {
					continued: true,
					underline: false
				})
			}
			
			if (selectedProduct.terms.withdrawal_modal) {
				const openWithdrawalModalLabel = getLanguageLabelFor(selectedProduct.terms.withdrawal_modal.button_open_label, undefined)!
				
				this.pdf.text(openWithdrawalModalLabel, linksX, marginTop, {
					continued: true,
					underline: true
				})
			}
			
			this.pdf.text('', {
				continued: false,
				underline: false
			})
		}
		
		marginTop = this.pdf.y
		
		return marginTop
	}
	
	/**
	 * Draw a parsed page section in the PDF
	 *
	 * @param {Section} section
	 *
	 * @returns {Promise<boolean>}
	 * @protected
	 */
	protected async drawParsedPageSection(section: Section): Promise<boolean> {
		let didRender = false
		
		try {
			this.moveCursorDown()
			await renderComponents(this, section.uiPage.components)
			this.addPage()
			didRender = true
		} catch (e) {
			log.always.error('[PDF] Could not render parsed page', section.uiPage.data.uid)
			log.always.error(e)
		}
		
		return didRender
	}
	
	/**
	 * Draw a screenshot in the PDF
	 * @param {string} screenshot
	 *
	 * @returns {Promise<void>}
	 *
	 * @protected
	 */
	protected async drawScreenshot(screenshot: string): Promise<void> {
		try {
			let image = this.pdf.openImage(screenshot)
			
			let width = image.width / 1.5/* / (window.devicePixelRatio || 1)*/
			let marginTop = this.sizes.headerY + 16
			
			this.pdf.image(image, (this.pageWidth / 2) - (width / 2), marginTop, {
				fit: [width, this.pageHeight - marginTop],
				valign: 'center',
				align: 'center'
			})
			
			this.addPage()
		} catch (e) {
			log.always.error('Error drawing screenshot:', e)
			// Ignore this page
		}
	}
	
	// Signatures
	
	/**
	 * Get the bankID logo as a data URI
	 *
	 * @param {string} type Either "bankid_mobile" or "bankid"
	 * @return {Promise<string>}
	 */
	static async getBankIDImage(type: string): Promise<string> {
		const filepath = type == 'bankid_mobile' ? 'bankid-mobil' : 'bankid'
		const url = new URL(`../../assets/bankid/${filepath}.png`, import.meta.url).href
		
		return fetchImageAsDataURI(url)
		
		// Does not work for some reason
		// return (await import(`../../assets/bankid/${filepath}.png?inline`)).default
	}
	
	/**
	 * Add all signatures to the document
	 *
	 * @param {string}      page                Page to use for the signatures
	 * @param {object[]}    persons             Persons to get the signatures and info from
	 */
	async drawSignatures(page: Page, persons: Person[]): Promise<void> {
		let signatureComponent: Component|null = null
		
		for (let component of page.components) {
			if (component.tag_normalized == 'signature') {
				signatureComponent = component
				break
			}
		}
		
		if (!signatureComponent) {
			throw new Error('Could not find signature component')
		}
		
		let absentLabel = this.parseLabel(signatureComponent.properties.absent_checkbox_label) || '',
			onBehalfLabel = this.parseLabel(signatureComponent.properties.on_behalf?.signed_label || '')
		
		let textSize = 14,
			textPadding = 8,
			personIndex = 0
		
		persons = persons.sort((a, b) => {
			if (a.role > b.role) {
				// aka a is buyer, b is seller
				return 1
			} else if (b.role > a.role) {
				// aka a is seller, b is buyer
				return -1
			}
			
			let aName = [a.name, a.firstname].filter(x => !!x).join(' '),
				bName = [b.name, b.firstname].filter(x => !!x).join(' ')
			
			return aName.localeCompare(bName)
		})
		
		for (let person of persons) {
			let currentY = this.sizes.headerY + 42,
				headerY = this.sizes.headerY + 10
			
			// Person info
			let name = [person.firstname, person.name].filter(x => !!x).join(' '), // Format name with spaces
				signature = parseSignature(person.signature)
			
			// Print Header
			this.setHeaderFont()
			this.pdf.text(this.parseLabel(page.data.name) + ': ', this.sizes.headerX, headerY, {
				continued: true
			}).font(this.fonts.bold).text(name).font(this.fonts.standard)
			
			if (person.absent) {
				this.setSubtextFont()
				this.pdf.text(absentLabel, 0, currentY += 48, this.style.textCentered)
				this.setHeaderFont()
			} else if (signature === null) {
				// TODO No signature
			} else {
				if (isParsedBankIDSignature(signature)) {
					let image = this.pdf.openImage(await PDF.getBankIDImage(person.signature_type!.type)), // Load BankID logo
						height = 41,
						scaledWidth = image.width * (height / image.height),
						strokePadding = this.sizes.signatureStrokePadding,
						strokeWidth = this.sizes.signatureStroke,
						signatureX = this.pageWidth / 2 - scaledWidth / 2 - strokeWidth - strokePadding
					
					currentY += this.pageHeight / 2 - height - strokePadding * 2 - strokeWidth - textPadding * 2 - textSize
					
					this.pdf.fontSize(12)
					this.pdf.fillColor(this.colors.subtext)
					this.pdf.strokeColor(this.colors.stroke)
					this.pdf.text('Signert med', signatureX, currentY)
					
					currentY += (textPadding * 2) + textSize
					
					// Print the bankID logo
					this.pdf.image(image, 0, currentY, {
						fit: [this.pageWidth, height],
						valign: 'center',
						align: 'center'
					}).rect(
						    // X
						    this.pageWidth / 2 - scaledWidth / 2 - strokePadding - strokeWidth,
						    // Y
						    currentY - strokePadding,
						    // width
						    scaledWidth + 2 * strokePadding,
						    // height
						    height + 2 * strokePadding
					    )
						// and a stroke around it
						.stroke()
					
					currentY += height + textPadding + textSize
					
					this.pdf.text(signature.name || '', signatureX, currentY)
					this.pdf.fontSize(this.sizes.smallFont)
					this.pdf.text(signature.person_number + ';' + signature.timestamp, signatureX, currentY += 14)
					
					this.setBodyFont()
				} else if (isParsedImageSignature(signature)) {
					try {
						// Open the image and define the scaled size
						// noinspection JSSwitchVariableDeclarationIssue
						let image = this.pdf.openImage(signature.datauri),
							height = Math.min(image.height, 184),
							scaledWidth = image.width * (height / image.height),
							strokePadding = this.sizes.signatureStrokePadding,
							strokeWidth = this.sizes.signatureStroke
						
						this.pdf.fontSize(12)
						this.pdf.strokeColor(this.colors.stroke)
						
						currentY += this.pageHeight / 2 - height - strokePadding * 2 - strokeWidth - textPadding * 2 - textSize
						
						// Print the signature
						this.pdf.image(image, 0, currentY, {
							fit: [this.pageWidth, height],
							valign: 'center',
							align: 'center'
						}).rect(
							    // X
							    this.pageWidth / 2 - scaledWidth / 2 - strokePadding - strokeWidth,
							    // Y
							    currentY - strokePadding,
							    // width
							    scaledWidth + 2 * strokePadding,
							    // height
							    height + 2 * strokePadding
						    )
							// and a stroke around it
							.stroke()
						
						currentY += height
					} catch (e) {
						this.pdf.text('Could not render signature', 0, currentY, this.style.textCentered)
						currentY += textSize
					}
				}
				
				// If power of attorney was used, inform here
				if (person.on_behalf?.myself === false) {
					let onBehalfY = this.sizes.headerY + this.sizes.headerFont + 16,
						fullLabel = onBehalfLabel ? onBehalfLabel + ': ' + person.on_behalf.name : person.on_behalf.name
					
					this.pdf.highlight(this.sizes.headerX, onBehalfY, this.pdf.widthOfString(fullLabel || ''), 12)
					
					if (onBehalfLabel) {
						this.pdf.text(onBehalfLabel + ': ', this.sizes.headerX, onBehalfY, {
							continued: true
						})
						
						this.pdf.font(this.fonts.bold)
						this.pdf.text(person.on_behalf.name || '')
						this.pdf.font(this.fonts.standard)
					} else {
						this.pdf.font(this.fonts.bold)
						this.pdf.text(person.on_behalf.name || '', this.sizes.headerX, onBehalfY)
						this.pdf.font(this.fonts.standard)
					}
				}
			}
			
			// If there is another person left, add another page for the next iteration
			if (personIndex + 1 < persons.length) {
				this.addPage()
			}
			
			personIndex++
		}
	}
	
	async getCheckboxIcon(): Promise<string> {
		if (!this.imageCache['checkbox']) {
			this.imageCache['checkbox'] = await svg2png(CheckboxIcon)
		}
		
		return this.imageCache['checkbox']
	}
	
	async getCheckboxMarkedIcon(): Promise<string> {
		if (!this.imageCache['checkbox-marked']) {
			this.imageCache['checkbox-marked'] = await svg2png(CheckboxMarkedGreenIcon)
		}
		
		return this.imageCache['checkbox-marked']
	}
	
	trimStringForDisplay(str: string): string {
		return trimEmojis(
			trimHTML(
				markdown.render(str)
			)
		)
	}
	
	/**
	 * End PDF editing.
	 * Required to get the data URI out.
	 */
	end(): this {
		this.pdf.end()
		
		return this
	}
	
	/**
	 * Output the data URI of the end result
	 *
	 * @return {Promise<string>}
	 */
	outputDataURI(): Promise<string> {
		return new Promise((resolve, reject) => {
			this.stream.on('finish', _ => {
				let reader = new FileReader(),
					blob = this.stream.toBlob()
				
				reader.onload = e => {
					const dataURI = e.target!.result as string
					resolve(dataURI)
				}
				
				reader.onerror = e => reject(e)
				reader.readAsDataURL(blob.slice(0, blob.size, 'application/pdf'))
			})
		})
	}
}
