import type { BankIDDataResponse, CreateImageCollectionResponse, CreateImageResponse, OrderResponse } from '@/lib/backend/types'
import type { UIResponse } from '@/lib/backend/types'
import type { Product } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import AxiosConnector from '@/lib/backend/AxiosConnector'

/**
 * Base class for implementing a backend
 * Also serves as the central point to check what the current backend is
 *
 * @author bluefirex
 * @version 1.0
 */
export default abstract class Backend extends AxiosConnector {
	// Protocols
	
	/**
	 * Get an order by its ticket
	 *
	 * @param {string} ticketID     Ticket ID
	 * @param {string} password     Password for the ticket
	 *
	 * @return {Promise<OrderResponse>}
	 */
	public abstract getOrderByTicket(ticketID: string, password: string): Promise<OrderResponse>
	
	/**
	 * Get an order using an API-provided token
	 *
	 * @param {string} token        Token
	 *
	 * @return {Promise<OrderResponse>}
	 */
	public abstract getOrderByToken(token: string): Promise<OrderResponse>
	
	/**
	 * Get the UI definition for a given token
	 *
	 * @param {string} token        Token
	 *
	 * @return {Promise<UIResponse}>}
	 */
	public abstract getUI(token: string): Promise<UIResponse>
	
	/**
	 * Submit a protocol for a given token
	 *
	 * @param {string} token    Token
	 * @param {object} payload  JSON Payload
	 *
	 * @return {Promise<void>}
	 */
	public abstract submitProtocol(token: string, payload): Promise<void>
	
	// Backups
	
	/**
	 * Get the latest available state backup for a given token
	 * Returns either a backup or null.
	 *
	 * @param {string} token    Token
	 *
	 * @return {Promise<object | null>}
	 */
	public abstract getLatestBackup(token: string): Promise<Record<string, any>|null>
	
	public abstract storeBackup(token: string, backup: any): Promise<void>
	
	public abstract deleteBackup(token: string): Promise<void>
	
	// Images
	
	/**
	 * Create an image collection
	 *
	 * @param {string}          token       Protocol Token
	 * @param {string}          title       Title of the collection
	 * @param {string}          semantic    Semantic value
	 * @param {Record<string, any>|null}  metadata    Metadata
	 *
	 * @returns {Promise<CreateImageCollectionResponse>}
	 */
	public abstract createImageCollection(token: string, title: string|null, semantic: string|null, metadata: Record<string, any>|null): Promise<CreateImageCollectionResponse>
	
	/**
	 * Update an image collection
	 *
	 * @param {string} token
	 * @param {string|number} imageCollectionID
	 * @param {string | null} title
	 * @param metadata
	 * @returns {Promise<any>}
	 */
	public abstract updateImageCollection(token: string, imageCollectionID: string|number, title: string|null, metadata: Record<string, any>|null): Promise<void>
	
	/**
	 * Upload an image to an image collection
	 *
	 * @param {string}          token               Protocol Token
	 * @param {string}          imageCollectionID   Image Collection ID
	 * @param {string}          image               Image as a data URI
	 * @param {string | null}   title               Title of the image
	 *
	 * @returns {Promise<CreateImageResponse>}
	 */
	public abstract uploadImage(token: string, imageCollectionID: string|number, image: string, title: string|null): Promise<CreateImageResponse>
	
	/**
	 * Delete an image collection
	 *
	 * @param {string}  token               Protocol Token
	 * @param {string}  imageCollectionID   Image Collection ID
	 *
	 * @returns {Promise<void>}
	 */
	public abstract deleteImageCollection(token: string, imageCollectionID: string|number): Promise<void>
	
	/**
	 * Delete an image
	 *
	 * @param {string}  token               Protocol Token
	 * @param {string}  imageCollectionID   Image Collection ID it was uploaded for
	 * @param {string}  imageID             Image ID
	 *
	 * @returns {Promise<void>}
	 */
	public abstract deleteImage(token: string, imageCollectionID: string|number, imageID: string|number): Promise<void>
	
	/**
	 * Get the URL to redirect to when user initiates bankID signing
	 *
	 * @param {string} token        Protocol Token
	 * @param {string} personID     Person's acquisit-assigned ID {@link Person.id}
	 * @param {string} returnURL    The URL to return back to after signing. The bankID signature service automatically
	 *                              appends a query param "status" with value "success", "abort" or "error". In case of
	 *                              "success", an additional query param "sessionId" is added to pass to {@link Backend.getBankIDData}
	 * @returns {string}
	 */
	public abstract getBankIDURL(token: string, personID: string, returnURL: string): string
	
	/**
	 * Get the signature data for a bankID signing session
	 *
	 * @param {string} token        Protocol Token
	 * @param {string} personID     Person's acquisit-assigned ID {@link Person.id}
	 * @param {string} sessionID    The signing session's ID, {@link Backend.getBankIDURL}
	 *
	 * @returns {Promise<BankIDDataResponse>}
	 */
	public abstract getBankIDData(token: string, personID: string, sessionID: string): Promise<BankIDDataResponse>
	
	// Products
	
	/**
	 * Get the terms for a specific product
	 *
	 * @param {string}      token       Protocol Token
	 * @param {Product}     product     Product ID
	 *
	 * @returns {Promise<string|null>}
	 */
	public abstract getProductTerms(token: string, product: Product): Promise<string|null>
	
	// Review after submission
	
	public abstract submitReview(token: string, score: number, comment: string): Promise<void>
}
