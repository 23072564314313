import type { Person } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { ValidationError } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { usePersonsStore } from '@/stores/persons'
import { useComponentsStore } from '@/stores/components'

export const validatePerson = async (person: Person): Promise<boolean> => {
	const personsStore = usePersonsStore(),
		componentsStore = useComponentsStore()
	
	const signatureComponents = componentsStore.by_tag['signature'],
		signatureComponent = signatureComponents[0] ?? null
	
	if (person.signature) {
		if (signatureComponent?.properties.contents_agreement_label && !person.content_agreed) {
			throw new ValidationError('Contents have not been agreed to', signatureComponent, 'content_agreed', { component_uid: signatureComponent.properties.uid })
		}
		
		personsStore.validatePerson(person)
		return true
	} else {
		if (signatureComponent) {
			throw new ValidationError('Signature missing', signatureComponent, 'signature', { component_uid: signatureComponent.properties.uid })
		} else {
			throw new Error('Signature missing for ' + person.id)
		}
	}
}

