/**
 * Pylon implementation for connecting to the Meglerfront Backend
 *
 * @author bluefirex
 * @version 1.0
 */
import type { BankIDDataResponse, CreateImageCollectionResponse, CreateImageResponse, OrderResponse, UIResponse } from '@/lib/backend/types'
import type { Product } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import Backend from '@/lib/backend/Backend'

export default class Pylon extends Backend {
	public readonly NAME = 'pylon'
	
	// Configuration
	
	public getBaseURL(opts) {
		return '/pyl'
	}
	
	protected getHeaders(opts) {
		return {}
	}
	
	getBankIDURL(token: string, personID: string, returnURL: string): string {
		const baseURL = window.location.protocol + '//' + window.location.host
		
		const url = new URL(baseURL + this.makeURL('/signicat/' + token + '/' + personID))
		url.searchParams.set('protocolUrl', returnURL)
		
		return url.href
	}
	
	async getBankIDData(token: string, personID: string, sessionID: string): Promise<BankIDDataResponse> {
		const response = await this.post('/signicat/' + token + '/' + personID + '/' + sessionID, {})
		
		return response.data
	}
	
	// Implementations
	
	async getOrderByTicket(ticketID: string, password: string): Promise<OrderResponse> {
		return this.custom('get', '/order/' + ticketID + '/' + password, {}, {
			headers: {
				'X-Navigator': navigator.userAgent,
				'X-Acquisit-Host': window.location.host
			}
		}, false).then(response => response.data)
	}
	
	async getOrderByToken(token: string): Promise<OrderResponse> {
		return this.custom('get', '/order/' + token + '?ts=' + (+new Date()), {}, {
			headers: {
				'X-Acquisit-Host': window.location.host
			}
		}, false).then(response => response.data)
	}
	
	async getUI(token: string): Promise<UIResponse> {
		return this.custom('get', '/order/' + token + '/ui?ts=' + (+new Date()), {}, {
			headers: {
				'X-Acquisit-Host': window.location.host
			}
		}, false).then(response => response.data)
	}
	
	async getLatestBackup(token: string): Promise<Record<string, any>|null> {
		return this.custom('get', '/backups/' + token + '/latest?ts=' + (+new Date()), {}, {
			headers: {
				'X-Acquisit-Host': window.location.host
			}
		}, false).then(response => response.data)
	}
	
	async storeBackup(token: string, backup: any): Promise<void> {
		await this.put('/backups/' + token, backup)
	}
	
	async deleteBackup(token: string): Promise<void> {
		await this.delete('/backups/' + token)
	}
	
	async submitProtocol(token: string, payload): Promise<void> {
		await this.put('/order/' + token + '/protocol', payload, false)
	}
	
	// Images
	
	async createImageCollection(token: string, title: string|null, semantic: string|null, metadata: Record<string, any>|null): Promise<CreateImageCollectionResponse> {
		return this.put('/order/' + token + '/collection', {
			title,
			semantic,
			metadata
		}).then(response => response.data)
	}
	
	async updateImageCollection(token: string, imageCollectionID: string|number, title: string|null, metadata: any): Promise<void> {
		await this.patch('/order/' + token + '/collections/' + imageCollectionID, {
			title,
			metadata
		})
	}
	
	async deleteImageCollection(token: string, imageCollectionID: string|number): Promise<void> {
		await this.delete('/order/' + token + '/collections/' + imageCollectionID)
	}
	
	async uploadImage(token: string, imageCollectionID: string|number, image: string, title: string|null): Promise<CreateImageResponse> {
		const response = await this.put('/order/' + token + '/collections/' + imageCollectionID + '/image', {
			image,
			title
		})
		
		return response.data
	}
	
	async deleteImage(token: string, imageCollectionID: string|number, imageID: string|number): Promise<void> {
		await this.delete('/order/' + token + '/collections/' + imageCollectionID + '/image/' + imageID)
	}
	
	// Review
	
	async submitReview(token: string, score: number, comment: string): Promise<void> {
		await this.put('/order/' + token + '/review', {
			score,
			comment
		})
	}
	
	// Products
	
	async getProductTerms(token: string, product: Product): Promise<string|null> {
		const productID = product.product_id ?? product.uid
		
		const url = product.product_type == 'electricity' ?
		            '/products/' + productID + '/terms/' + token :
		            '/all-products/' + productID + '/terms/' + token
		
		return this.custom('get', url + '?ts=' + (+new Date()), null, {
			headers: {
				'Content-Type': 'text/plain'
			}
		}).then(response => response.data)
	}
}