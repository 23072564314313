/**
 * Asgard's implementation for connecting to the Asgard Backend
 * The OG Backend \o/
 *
 * @author bluefirex
 * @version 1.0
 */
import type { BankIDDataResponse, CreateImageCollectionResponse, CreateImageResponse, OrderResponse, UIResponse } from '@/lib/backend/types'
import type { Product } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import Backend from '@/lib/backend/Backend'

export default class Asgard extends Backend {
	public readonly NAME = 'asgard'
	
	// Configuration
	
	public getBaseURL(opts) {
		return '/asg/acquisit'
	}
	
	protected getHeaders(opts) {
		return {
			'X-Asgard-Debug': '0xDebuggyMcDebugface'
		}
	}
	
	// BankID
	
	getBankIDURL(token: string, personID: string, returnURL: string): string {
		const url = new URL(window.location.href)
		url.pathname = '/bankid-dummy.html'
		url.searchParams.set('protocolUrl', returnURL)
		
		return url.href
	}
	
	public async getBankIDData(token: string, personID: string, sessionID: string): Promise<BankIDDataResponse> {
		return {
			common_name: 'Sample Person',
			person_number: '010490-12345',
			timestamp: Math.floor((+new Date()) / 1000)
		}
	}
	
	// Implementations
	
	async getOrderByTicket(ticketID: string, password: string): Promise<OrderResponse> {
		return this.custom('get', '/order/' + ticketID + '/' + password + '?ts=' + (+new Date()), {}, {
			headers: {
				'X-Navigator': navigator.userAgent,
				'X-Acquisit-Host': window.location.host
			}
		}, false).then(response => response.data)
	}
	
	async getOrderByToken(token: string): Promise<OrderResponse> {
		return this.get('/order/' + token + '?ts=' + (+new Date()), {}, false)
		           .then(response => response.data)
	}
	
	async getUI(token: string): Promise<UIResponse> {
		return this.get('/order/' + token + '/ui?ts=' + (+new Date()), {}, false)
		           .then(response => response.data)
	}
	
	async getLatestBackup(token: string): Promise<Record<string, any>|null> {
		return this.get('/backups/' + token + '/latest?ts=' + (+new Date()), {}, false).then(response => response.data)
	}
	
	async storeBackup(token: string, backup: any): Promise<void> {
		await this.put('/backups/' + token, backup)
	}
	
	async deleteBackup(token: string): Promise<void> {
		await this.delete('/backups/' + token)
	}
	
	async submitProtocol(token: string, payload): Promise<void> {
		await this.custom('put', '/order/' + token + '/protocol', payload, {
			headers: {
				'X-Acquisit-Host': window.location.host
			}
		}, true)
	}
	
	// Images
	
	async createImageCollection(token: string, title: string|null, semantic: string|null, metadata: Record<string, any>|null): Promise<CreateImageCollectionResponse> {
		return this.put('/order/' + token + '/collection', {
			title,
			semantic,
			metadata
		}).then(response => response.data)
	}
	
	async updateImageCollection(token: string, imageCollectionID: string|number, title: string|null, metadata: any): Promise<void> {
		await this.patch('/order/' + token + '/collections/' + imageCollectionID, {
			title,
			metadata
		})
	}
	
	async deleteImageCollection(token: string, imageCollectionID: string|number): Promise<void> {
		await this.delete('/order/' + token + '/collections/' + imageCollectionID)
	}
	
	async uploadImage(token: string, imageCollectionID: string|number, image: string, title: string|null): Promise<CreateImageResponse> {
		return this.put('/order/' + token + '/collections/' + imageCollectionID + '/image', {
			image,
			title
		}).then(response => response.data)
	}
	
	async deleteImage(token: string, imageCollectionID: string|number, imageID: string): Promise<void> {
		await this.delete('/order/' + token + '/collections/' + imageCollectionID + '/image/' + imageID)
	}
	
	// Review
	
	async submitReview(token: string, score: number, comment: string): Promise<void> {
		await this.put('/order/' + token + '/review', {
			score,
			comment
		})
	}
	
	// Products
	
	async getProductTerms(token: string, product: Product): Promise<string|null> {
		const productID = product.product_id ?? product.uid
		
		return this.custom('get', '/order/' + token + '/products/' + productID + '/terms?ts=' + (+new Date()), null, {
			headers: {
				'Content-Type': 'text/plain'
			}
		}).then(response => response.data)
	}
}
